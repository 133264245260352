@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700;800;900&display=swap);
* {
    padding: 0;
    box-sizing: border-box;
    font-size: 100%;
}
:root {
    --mainWhite: #fcfcfc;
    --mainLightGray: #F8F8F8;
    --mainDarkerGray: #D8D8D8;
    --mainDarkestGray: #707070;
    --mainDark: #0b0d0e;
    --lightDark: #161a1b;
    --mainBlack:#1a202d;
    --sunflowerYellow: #e8c13a;
    --offWhite: #f0f0f0; 
}

html.dark{
background-color: #0b0d0e;
background-color: var(--mainDark);
color: #f0f0f0;
color: var(--offWhite);
}

html.light{
    background: linear-gradient(90deg, #F8F8F8 1%, transparent 1%) 0.5px 0, #fff;
    color: #0b0d0e;
    color: var(--mainDark);
    background-position: -1px;
    background-size: calc((100% / 8) + 0.2px);
}

body {
    /* background: linear-gradient(90deg, #F8F8F8 1%, transparent 1%) 0.5px 0, #fff;
    color: var(--mainDark);
    background-position: -1px;
    background-size: calc((100% / 8) + 0.2px); */
    -webkit-font-smoothing: antialiased;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    text-rendering: optimizeLegibility;
    margin: 0;
}

.container, main {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    /* max-width: 1280px; */
}
.nav__logo,
.nav__link {
    text-transform: lowercase;
}

/* Customized */
h1 {
    font-weight: 900;
    font-family: 'Poppins', sans-serif;
}

h1.firstTitle,
h1.secondTitle,
h1.thirdTitle {
    font-size: 2.5rem;
}

.thirdTitle {
    margin-bottom: 0.5rem
}
h2,
h3 {
    font-weight: 800;
    /* color: var(--mainDark); */
    font-family: 'Gatwick-Bold', sans-serif;
}
h4,
h5,
h6 {
    font-weight: 400;
    font-family: 'Gatwick-Ultralight', sans-serif;
}
p {
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 1rem;
}
span, a  {
    font-family: 'Gatwick-Ultralight', sans-serif;
}
ul {
    list-style: none;
}
li {
    max-width: 60ch;
    list-style: none;
}

/** Only shadows**/
/* .projects_outer__wrapper {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 9px 46px 8px rgba(0, 0, 0, .12), 0 24px 38px 13px rgba(0, 0, 0, .14);
} */
/* .section__wrapper {
    box-shadow: 0 1px 15px -7px rgba(238, 234, 234, 0.2), 0 9px 46px 8px rgba(333, 333, 333, .12), 0 24px 38px 3px rgba(0, 0, 0, .14); 
} */

/* animated vertical scroll  */
.fixed-misc {
    position: fixed;
    top: 50%;
    right: -90px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    letter-spacing: 1.5px;
    mix-blend-mode: difference;
    z-index: 9;
    font-size: 1rem;
}

@media screen and (max-width:600px) {
h1.firstTitle,h1.secondTitle,
h1.thirdTitle{
        font-size: 2rem;
    }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@font-face {
    font-family: Gatwick-Bold;
    font-weight: 900;
    src: url(/assets/Gatwick/Gatwick-Bold.otf) format("otf"),
        local("HelveticaNeue-Bold"),
}

@font-face {
    font-family: Gatwick-Ultralight;
    font-weight: 200;
    src: url(/assets/Gatwick/Gatwick-Ultralight.otf) format("otf"),
        local("HelveticaNeue-Bold"),
}
