@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@font-face {
    font-family: Gatwick-Bold;
    font-weight: 900;
    src: url(/assets/Gatwick/Gatwick-Bold.otf) format("otf"),
        local("HelveticaNeue-Bold"),
}

@font-face {
    font-family: Gatwick-Ultralight;
    font-weight: 200;
    src: url(/assets/Gatwick/Gatwick-Ultralight.otf) format("otf"),
        local("HelveticaNeue-Bold"),
}